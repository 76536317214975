<script setup lang="ts">
import dayjs from 'dayjs';
import type { ProductionTime } from '~/types/analytic';
import type { OffProductionConsumption } from './../../types';

// Components
import OffConsumptionGraph from '~/app-modules/explore/components/graphs/OffConsumptionGraph.vue';

// Composables
const { getOffProductionByMeterId } = useApi();
const route = useRoute();

// Props
const props = defineProps<{
  dateRange: { start: string; end: string };
}>();

// Data
const modal = ref(false);
const data = reactive<{
  productionTimes: ProductionTime[];
  offProductionConsumption: OffProductionConsumption[];
  baselinePowerKW: 0;
  selectedConsumption: OffProductionConsumption | null;
  analyticType: string;
}>({
  productionTimes: [],
  offProductionConsumption: [],
  baselinePowerKW: 0,
  selectedConsumption: null,
  analyticType: 'grdf',
});

const state = reactive({
  isLoading: false,
});

// Load data
async function loadData() {
  state.isLoading = true;

  const meter = meterStore().getMeter(parseInt(route.params.id as string));
  if (!meter) return;

  const site = siteStore().getSite(meter?.area.site_id);
  const productionTimes = site?.configs?.production_times;
  data.productionTimes = productionTimes === undefined ? [] : productionTimes;
  data.analyticType = meter.meter_type.name;

  if (meter.meter_type.name !== 'grdf') {
    if (data.productionTimes.length !== 0) {
      const consumptionData = await getOffProductionByMeterId({
        meterId: route.params.id as string,
        start: props.dateRange.start,
        end: props.dateRange.end,
      });
      data.baselinePowerKW = consumptionData.baseline_power_kW;
      data.offProductionConsumption = consumptionData.offProductionConsumptions.map((value: any) => {
        return {
          ...value,
          week: new Date(value.week),
          start: new Date(value.start),
          end: new Date(value.end),
        };
      });
    }
  } else {
    data.baselinePowerKW = 0;
    data.offProductionConsumption = [];
  }
  state.isLoading = false;
}

watch(
  () => route.params.id,
  () => {
    loadData();
  },
  { immediate: true },
);

// Computed
const unsetProductionTimes = computed(() => {
  return data.productionTimes.length === 0;
});

const alert = computed(() => {
  return alertStore().getAlertByMeterIdAndType(parseInt(route.params.id as string), 'off_production');
});

const checkDatesSameAsToday = computed(() => {
  return data.productionTimes.some((value) => {
    return dayjs().isSame(dayjs(value.updated_at), 'day');
  });
});

watch(
  [route, props],
  () => {
    loadData();
  },
  { deep: true },
);
</script>

<template>
  <div>
    <template v-if="!state.isLoading">
      <template v-if="data.analyticType !== 'grdf'">
        <template v-if="!unsetProductionTimes">
          <div class="flex flex-col">
            <ui-warning v-if="checkDatesSameAsToday" class="my-4" :message="$t('global.production_times_updated')" />

            <ui-card :title="$t('analytics.off_production_analysis')">
              <!-- Alert -->
              <ui-info
                v-if="alert"
                class="mb-4"
                :message="$t('global.alert_set_as', { value: alert?.params.standard_value }) + ' kW'"
                :link="$t('global.update')"
                @click-link="modal = true"
              />
              <ui-info v-else class="mb-4" :link="$t('global.create')" :message="$t('global.no_alert_set')" @click-link="modal = true" />

              <!-- Graph -->
              <OffConsumptionGraph
                v-if="data.offProductionConsumption.length !== 0"
                :off-production-consumptions="data.offProductionConsumption"
                :alert="alert"
                :baseline-power-k-w="data.baselinePowerKW"
              />
              <ui-warning v-else class="mb-4" :message="$t('analytics.no_off_production_data_on_selected_date_range')" />
            </ui-card>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col items-center justify-center flex-1 w-full h-full gap-4">
            <div class="flex flex-col items-center justify-center gap-2 text-[#E26530] text-xl">
              <ui-icon name="Ghost" />
              <p>
                {{ $t('analytics.no_production_times') }}
              </p>
            </div>
            <button class="btn-primary bg-[#EC9875] hover:bg-[#E26530]" @click="$router.push(`/configs?tab=1`)">
              {{ $t('analytics.configure_production_hours') }}
            </button>
          </div>
        </template>
      </template>
      <template v-else>
        <div v-if="data.analyticType === 'grdf'" class="flex flex-col items-center justify-center flex-1 w-full h-full gap-4">
          <div class="flex flex-col items-center justify-center gap-2 text-[#E26530] text-xl">
            <ui-icon name="Ghost" />
            <p>
              {{ $t('analytics.off_production_analysis_not_available') }}
            </p>
          </div>
        </div>
      </template>
    </template>
    <div v-else class="flex justify-center">
      <ui-loader />
    </div>

    <!-- Modals -->
    <alert-modal v-model="modal" type="off_production" :alert-id="alert?.id" />
  </div>
</template>
